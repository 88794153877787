import React, { Component } from "react";
import { graphql, StaticQuery } from "gatsby";
import debounce from "lodash.debounce";
import "./footer.sass";

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
    };
  }

  componentDidMount() {
    window.addEventListener(
      "scroll",
      debounce(() => {
        if (
          window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.offsetHeight * 0.8
        ) {
          this.setState({
            items: this.state.items.concat(Array.from({ length: 20 })),
          });
        }
      }, 10)
    );
  }

  alertAndCopyContact = (contact) => {
    navigator.clipboard.writeText(contact)
      .then(() => alert(contact + " — copied to clipboard! :)"))
      .catch(err => {
        alert(contact + " — say hi!")
        console.error("Failed to copy to clipboard: ", err)
      });
  };

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            friendnet: markdownRemark(
              frontmatter: { for: { eq: "friendnet" } }
            ) {
              frontmatter {
                content {
                  name
                  site
                }
              }
            }
            eeto: markdownRemark(frontmatter: { for: { eq: "eeto" } }) {
              frontmatter {
                content {
                  eeto
                  site
                }
              }
            }
          }
        `}
        render={(data) => {
          const eeto = data.eeto.frontmatter.content;

          return (
            <footer className="footer">
              <div className="content has-text-centered">
                <span>gimme a message</span>
                <br />
                <img
                  className="icon"
                  src="/img/icons/mail.svg"
                  alt="email"
                  onClick={() => this.alertAndCopyContact("hi@" + "trinityjchung.com")}
                  style={{ cursor: "pointer" }}
                />
                <img
                  className="icon"
                  src="/img/icons/signal.svg"
                  alt="signal messenger"
                  onClick={() =>
                    this.alertAndCopyContact("trinityc.03")
                  }
                  style={{ cursor: "pointer" }}
                />
                <a href="https://github.com/milotrince">
                  <img className="icon" src="/img/icons/github.svg" alt="github"/>
                </a>
                <a href="https://bsky.app/profile/trinityjchung.com">
                  <img className="icon" src="/img/icons/bluesky.svg" alt="bsky"/>
                </a>
                <a href="https://www.linkedin.com/in/trinitychung/">
                  <img className="icon" src="/img/icons/linkedin.svg" alt="linkedin"/>
                </a>

                <div className="mx-auto p-4" style={{ maxWidth: "700px" }}>
                  <p className="eeto">
                    {eeto.map((f, index) => (
                      <a key={index} href={f.site}>
                        <img src={`/img/8831/${f.eeto}`} alt="eeto"/>
                      </a>
                    ))}
                  </p>
                </div>

                <p>site last updated: 2025/03/20</p>
              </div>

              <div>
                {this.state.items.map((_, index) => (
                  <span
                    key={index}
                    className="test"
                    style={{ opacity: `${Math.min(index / 2000, 1)}` }}
                  >
                    the end is never&nbsp;
                  </span>
                ))}
              </div>
            </footer>
          );
        }}
      />
    );
  }
}

export default Footer;